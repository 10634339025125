import React, {useEffect, useReducer} from "react"
import { reducer, initialState } from "./reducer"

const UserContext = React.createContext()

function UserProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        fetchUserData()
    }, [])

    useEffect(() => {
        if (state.paymentStatus) {
            fetchUserData()
        }
    }, [state.paymentStatus])

    function fetchUserData() {
        fetch('/me')
            .then(res => res.json())
            .then(data => {
                dispatch({type: 'setUser', payload: data})
                data.error ? dispatch({type: "setLoggedIn", payload: false}) : dispatch({type: "setLoggedIn", payload: true})
                dispatch ({type: "setLoad"})
            })
    }

    useEffect(() => {
        fetch('/games')
            .then(res => res.json())
            .then(data => dispatch({type: "fetchGames", payload: data}))
        fetch('/daily_three_year_stats')
            .then(res => res.json())
            .then(data => dispatch({type: "fetchDailyThreeYearStats", payload: data}))
        // fetch('/authors')
        //     .then(res => res.json())
        //     .then(data => dispatch({type: "fetchAuthors", payload: data}))
        // fetch('/articles')
        //     .then(res => res.json())
        //     .then(data => dispatch({type: "fetchArticles", payload: data}))
    }, [])

    useEffect(() => {
        if (state.loggedIn) {
            fetch('/daily_current_season_stats')
                .then(res => res.json())
                .then(data => dispatch({type: "fetchDailyCurrentSeasonStats", payload: data}))
            fetch('/team_trends')
                .then(res => res.json())
                .then(data => dispatch({type: "fetchTeamTrends", payload: data}))
            fetch('/daily_pitcher_current_season_stats')
                .then(res => res.json())
                .then(data => dispatch({type: "fetchDailyPitcherCurrentSeasonStats", payload: data}))
            fetch('/daily_pitcher_three_year_stats')
                .then(res => res.json())
                .then(data => dispatch({type: "fetchDailyPitcherThreeYearStats", payload: data}))
        }
    }, [state.loggedIn])

    function login(user) {
        dispatch({type: "login", payload: user})
    }

    function logout() {
        dispatch({type: "logout"})
    }

    function signup(user) {
        dispatch({type: "signup", payload: user})
    }

    return (
        <UserContext.Provider value={{login, logout, signup, state, dispatch}}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }