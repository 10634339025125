import React, {useContext, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import {useNavigate} from "react-router-dom"
import HomeGameBar from "./HomeGameBar";
import Logos from "./Logos";
import TableImage from "../images/new ratings screenshot.png"
import GraphImage from "../images/hitter trends screenshot.png"
import SignupLoginButtons from "./SignupLoginButtons";
import TimeframeImage from "../images/timeframe select screenshot.png"
import PitcherTableImage from "../images/pitcher ratings screenshot.png"
import NavBar from "./NavBar";
import TweetImage1 from "../images/tweet 1.png"
import TweetImage4 from "../images/tweet 4.png"
import TweetImage7 from "../images/tweet 7.png"
import TweetImage8 from "../images/tweet 8.png"
import TweetImage9 from "../images/tweet 9.png"
import TweetImage10 from "../images/tweet 10.png"
import TweetImage11 from "../images/tweet 11.png"
import TweetImage12 from "../images/tweet 12.png"

function NewHome() {
    const {state} = useContext(UserContext);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    //console.log('state', state)
    const tweetImages = [TweetImage1, TweetImage4, TweetImage7, TweetImage8, TweetImage9, TweetImage10, TweetImage11, TweetImage12]
    const navigate = useNavigate()

    function navigateToBreakdown() {
        navigate('/guide')
        window.scrollTo(0, 0)
    }

    function handlePremiumNavigation() {
        navigate('/checkout')
        window.scrollTo(0, 0)
    }

    function navigateToHitterRatings() {
        navigate('/ratings/hitters')
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % tweetImages.length)
        }, 7000)

        return () => clearInterval(intervalId)
    }, [tweetImages.length])

    const isUser = state.loggedIn ? true : false
    // const subscription = state.user.subscription
    // //console.log('sub', subscription)
    // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    // //console.log('hasSub?', hasSub)
    // const today = new Date()
    // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    if (isUser) {
        return (
            <div>
                <HomeGameBar /> 
                <NavBar />
                <Logos />
                <div className="newHomeHeader">
                    <h1>MLB Tools and Data</h1>
                    {/* <h3>For MLB Betting and DFS</h3> */}
                </div>
                <div className="newHomeContainer">
                    <div className="newHomeBoxOdd">
                        <h2>Find the Best Matchups!</h2>
                        <h3 onClick={navigateToHitterRatings}>Discover Our Unique Ratings</h3>
                        <img id="newHomeTableImage" src={TableImage} alt="hitter table" onClick={navigateToBreakdown}></img>
                        <p id="newHomeTableP" onClick={navigateToBreakdown}>Explore Our In-Depth Guide and Master Using the Ratings</p>
                    </div>
                    <div className="newHomeBoxEven">
                        <h2>Make Smarter Decisions</h2>
                        <img id="graphImage" src={GraphImage} alt="hitter graph"></img>
                        <p>Leverage Our Hitter and Pitcher Trends to Determine How Often The Batter or Pitcher Succeeds with an Elite Matchup</p>
                    </div>
                    <div className="newHomeBoxOdd">
                        <h2>Stay Current</h2>
                        <img id="timeframeImage" src={TimeframeImage} alt="timeframe select"></img>
                        <p>Use Both Default and Current Season Ratings.</p>
                        <p>Default ratings offer larger sample sizes, while current season ratings focus on recent performance.</p>
                    </div>
                    <div className="newHomeBoxEven">
                        <h2>Mound Metrics</h2>
                        <img id="pitcherTableImage" src={PitcherTableImage} alt="hitter graph"></img>
                        <p>Unlock Pitcher Ratings for Insights on Team Totals, Strikeout Props, and More!</p>
                    </div>
                    <div className="newHomeBoxOdd">
                        <h2>What Our Users Say About Us</h2>
                        <img id="tweetImage" src={tweetImages[currentImageIndex]} alt="tweet testimonials"></img>
                    </div>
                </div>
            </div>
        )
    }
    else if (state.loggedIn === true) {
        return (
            <div className="newHomeBody">
                <HomeGameBar /> 
                <NavBar />
                <Logos />
                <div className="newHomeHeader">
                    <h1>MLB Tools and Data</h1>
                    {/* <h3>For MLB Betting and DFS</h3> */}
                </div>
                <div className="newHomeContainer">
                    <div className="newHomeBoxOdd">
                        <h2>Find the Best Matchups!</h2>
                        <h3 onClick={navigateToHitterRatings}>Discover Our Unique Ratings</h3>
                        <div className="newHomePremium">
                            <p style={{textAlign: "center"}}>Upgrade to Premium for Extra Tools and Features</p>
                            <button onClick={handlePremiumNavigation}>Get Premium</button>
                        </div>
                        <img id="newHomeTableImage" src={TableImage} alt="hitter table" onClick={navigateToBreakdown}></img>
                        <p id="newHomeTableP" onClick={navigateToBreakdown}>Explore Our In-Depth Guide and Learn Ways to Use the Ratings</p>
                    </div>
                    <div className="newHomeBoxEven">
                        <h2>Make Smarter Decisions</h2>
                        <img id="graphImage" src={GraphImage} alt="hitter graph"></img>
                        <p>Leverage Our Hitter and Pitcher Trends to Determine How Often The Batter or Pitcher Succeeds with an Elite Matchup</p>
                    </div>
                    <div className="newHomeBoxOdd">
                        <h2>Stay Current</h2>
                        <img id="timeframeImage" src={TimeframeImage} alt="timeframe select"></img>
                        <p>Use Both Default and Current Season Ratings.</p>
                        <p>Default ratings offer larger sample sizes, while current season ratings focus on recent performance.</p>
                    </div>
                    <div className="newHomeBoxEven">
                        <h2>Mound Metrics</h2>
                        <img id="pitcherTableImage" src={PitcherTableImage} alt="hitter graph"></img>
                        <p>Unlock Pitcher Ratings for Insights on Team Totals, Strikeout Props, and More!</p>
                    </div>
                    <div className="newHomeBoxOdd">
                        <h2>What Our Users Say About Us</h2>
                        <img id="tweetImage" src={tweetImages[currentImageIndex]} alt="tweet testimonials"></img>
                    </div>
                </div>
            </div>
        )
    }
    else return (
        <div>
            <HomeGameBar /> 
            <NavBar />
            <Logos />
            <div className="newHomeHeader">
                <h1>MLB Tools and Data</h1>
                {/* <h3>For MLB Betting and DFS</h3> */}
            </div>
            <SignupLoginButtons />
            <div className="newHomeContainer">
                <div className="newHomeBoxOdd">
                    <h2>Find the Best Matchups!</h2>
                    <h3 onClick={navigateToHitterRatings}>Disover Our Unique Ratings</h3>
                    <div className="newHomePremium">
                        <p style={{textAlign: "center"}}>Upgrade to Premium for Extra Tools and Features</p>
                        <button onClick={handlePremiumNavigation}>Get Premium</button>
                    </div>
                    <img id="newHomeTableImage" src={TableImage} alt="hitter table" onClick={navigateToBreakdown}></img>
                    <p id="newHomeTableP" onClick={navigateToBreakdown}>Explore Our In-Depth Guide and Learn Ways to Use the Ratings</p>
                </div>
                <div className="newHomeBoxEven">
                    <h2>Make Smarter Decisions</h2>
                    <img id="graphImage" src={GraphImage} alt="hitter graph"></img>
                    <p>Leverage Our Hitter and Pitcher Trends to Determine How Often The Batter or Pitcher Succeeds with an Elite Matchup</p>
                </div>
                <div className="newHomeBoxOdd">
                    <h2>Stay Current</h2>
                    <img id="timeframeImage" src={TimeframeImage} alt="timeframe select"></img>
                    <p>Use Both Default and Current Season Ratings.</p>
                    <p>Default ratings offer larger sample sizes, while current season ratings focus on recent performance.</p>
                </div>
                <div className="newHomeBoxEven">
                    <h2>Mound Metrics</h2>
                    <img id="pitcherTableImage" src={PitcherTableImage} alt="hitter graph"></img>
                    <p>Unlock Pitcher Ratings for Insights on Team Totals, Strikeout Props, and More!</p>
                </div>
                <div className="newHomeBoxOdd">
                    <h2>What Our Users Say About Us</h2>
                    <img id="tweetImage" src={tweetImages[currentImageIndex]} alt="tweet testimonials"></img>
                </div>
            </div>
        </div>
    )
}

export default NewHome