import React, {useState, useContext, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import GameBar from "./GameBar";
import Logos from "./Logos";
import GetPremium from "./GetPremium";
import HitterRatingsTable from "./HitterRatingsTable";

function HitterRatings() {
    const {state} = useContext(UserContext);
    //console.log('state', state)
    //console.log('user', state.user.email.length)
    const [ratingTimeframe, setRatingTimeframe] = useState(false)
    const [formattedDate, setFormattedDate] = useState("")
    //false is default (last 3 seasons), true is current season only
    //console.log('ratingTimeFrame', ratingTimeframe)
    //console.log('state', state)

    useEffect(() => {
        if (state?.games.length > 0) {
            // const statCreatedDate = state?.games[0].created_at
            // const [datePart] = statCreatedDate.split('T')
            // const [year, month, day] = datePart.split('-')
            //setFormattedDate(`${parseInt(month)}-${day}`)
            setFormattedDate(`9-29`)
        }
    }, [state.games])

    function handleChange() {
        setRatingTimeframe(prevTimeframe => !prevTimeframe)
    }

    const isUser = state.loggedIn ? true : false
    // const subscription = state.user.subscription
    // //console.log('sub', subscription)
    // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    // //console.log('hasSub?', hasSub)
    // const today = new Date()
    // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

     return (
        <div>
            <Logos />
            {!isUser && (<GetPremium className="ratingsGetPremium"/>)}
            {formattedDate && (<h2 className="ratings-header">MLB Hitter Ratings for {formattedDate}</h2>)}
            <GameBar />
            {isUser && (
                <div className="ratings-dropdown">
                    <label>Select Rating Timeframe: </label>
                    <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                        <option>Default (Last 3 Seasons)</option>
                        <option>Current Season Only</option>
                    </select>
                </div>
            )}
            <HitterRatingsTable ratingTimeframe={ratingTimeframe}/>
        </div>
    )
}

export default HitterRatings