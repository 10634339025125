import React, {useContext, useState, useEffect, useMemo} from "react";
import { UserContext } from '../Context/UserContext';
import ColorLabels from "./ColorLabels";
import PitcherGlossary from "./PitcherGlossary"

function PitcherRatingsTable({ratingTimeframe}) {
    //console.log('ratingTimeframe on pitcher table', ratingTimeframe)
    const {state} = useContext(UserContext);
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")
    //console.log('dataTimeframe on pitcher table', dataTimeframe)
    const [filteredPitcherStats, setFilteredPitcherStats] = useState(state[`dailyPitcher${dataTimeframe}Stats`])
    //console.log('filteredPitcherStats', filteredPitcherStats)
    const [sortDirection, setSortDirection] = useState(true)
    const [sortState, setSortState] = useState("Matchup Rating")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
    }, [ratingTimeframe])

    useEffect(() => {
        const pitcherStatsKey = `dailyPitcher${dataTimeframe}Stats`
        if (Array.isArray(state[pitcherStatsKey])) {
            setFilteredPitcherStats(state[pitcherStatsKey])
        }
        
    }, [state, dataTimeframe])

    function handleSort(e) {
        const category = e.target.textContent.toLowerCase().replace(/ /g, "_");
        if (e.target.textContent === "Matchup Rating") {
            setSortState("pitcher_matchup_rating_thirty_percent")
        }
        else {
            setSortState(category)
        }
        setSortDirection((prevDirection) => !prevDirection)
    }

    function handleChange(e) {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined)
        setFilteredPitcherStats(state[`dailyPitcher${dataTimeframe}Stats`])
    else
        setFilteredPitcherStats(state[`dailyPitcher${dataTimeframe}Stats`].filter(stat => stat.pitcher_name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const sortedPitchers = useMemo(() => {
        if (!filteredPitcherStats || !Array.isArray(filteredPitcherStats)) return []
        const sortedStats = [...(filteredPitcherStats || [])]?.sort((a, b) => {
            const aValue = a?.[sortState] || 0;
            const bValue = b?.[sortState] || 0;
            const comparison = aValue - bValue;
            return sortDirection ? comparison : -comparison;
        });
        return sortedStats;
    }, [filteredPitcherStats, sortState, sortDirection])

    if (filteredPitcherStats.length === 0) {
        return (
            <div className="spinner">
            </div>
        )
    }
    else {
        return (
            <div className="ratings-container">
                <div className="searchBar">
                    <label>Search A Pitcher: </label>
                    <input type="text" placeholder="Ex: Justin Steele" onChange={(e) => handleChange(e)}></input>
                </div>
                <ColorLabels />
                <PitcherGlossary />
                <div className="pitcher-table-container">
                    <table className="pitcher-ratings-table">
                        <thead>
                            <tr>
                                {/* <th>Favorite</th> */}
                                <th>Name</th>
                                <th>Team</th>
                                <th>Throw Hand</th>
                                <th>Opponent</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Matchup Rating</th>
                                <th>IP</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup Rating</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup wOBA</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup ISO</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup Hard Contact</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup K Pct</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup GB Pct</th>
                                <th>Elite Rated Hitters</th>
                                <th>Poor Rated Hitters</th>
                                <th>Hitters Above MLB Avg K Pct</th>
                                <th>Hitters Above MLB Avg GB Pct</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPitchers?.map((stat) => {
                                const teamStyles = {backgroundColor: state.teamColors[stat.pitcher_team] || "transparent"};
                                const strikeoutRateStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_k_pct >= 30.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_k_pct >= 26.0 && stat.avg_hitter_matchup_k_pct < 30.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_k_pct > 23.0 && stat.avg_hitter_matchup_k_pct < 26.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_k_pct <= 20.0 ? 'lightpink' : '')
                                }
                                const groundballRateStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_gb_pct >= 50.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_gb_pct >= 46.0 && stat.avg_hitter_matchup_gb_pct < 50.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_gb_pct > 43.0 && stat.avg_hitter_matchup_gb_pct < 46.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_gb_pct <= 37.0 ? 'lightpink' : '')
                                }
                                const hardContactStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_hard_contact <= 26.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_hard_contact >= 26.01 && stat.avg_hitter_matchup_hard_contact < 29.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_hard_contact >= 29.01 && stat.avg_hitter_matchup_hard_contact < 32.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_hard_contact >= 36.5 ? 'lightpink' : '')
                                }
                                const isoStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_iso <= 0.129 ? 'lightblue' : '') || (stat.avg_hitter_matchup_iso >= 0.130 && stat.avg_hitter_matchup_iso < 0.140 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_iso >= 0.140 && stat.avg_hitter_matchup_iso < 0.150 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_iso >= 0.175 ? 'lightpink' : '')
                                }
                                const wobaStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_woba <= 0.280 ? 'lightblue' : '') || (stat.avg_hitter_matchup_woba > 0.280 && stat.avg_hitter_matchup_woba < 0.300 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_woba >= 0.300 && stat.avg_hitter_matchup_woba < 0.330 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_woba >= 0.350 ? 'lightpink' : '')
                                }
                                const matchupRatingStyles = {
                                    backgroundColor: (stat.pitcher_matchup_rating_thirty_percent <= 5.63 ? 'lightblue' : '') || (stat.pitcher_matchup_rating_thirty_percent > 5.63 && stat.pitcher_matchup_rating_thirty_percent <= 5.85 ? 'lightgreen' : '') || (stat.pitcher_matchup_rating_thirty_percent > 5.85 && stat.pitcher_matchup_rating_thirty_percent < 6.05 ? 'lightyellow' : '') || (stat.pitcher_matchup_rating_thirty_percent >= 6.4 ? 'lightpink' : '')
                                }
                                return (
                                    <tr key={stat.id}>
                                        {/* <td className={favoritePitchers.some(item => item.pitcher_id === stat.pitcher_id && item.user_id === userID && item.timeframe === "three year") ? "favorite" : ""}>{stat?.favorite_count} {<button className="favButton" onClick={() => handleFavoriteCount(stat.pitcher_id)}>⭐</button>}</td> */}
                                        <td>{stat?.pitcher_name}</td>
                                        <td style={teamStyles}>{stat?.pitcher_team}</td>
                                        <td>{stat?.throw_hand}</td>
                                        <td>{stat?.hitter_team}</td>
                                        <td style={matchupRatingStyles}>{stat?.pitcher_matchup_rating_thirty_percent}</td>
                                        <td>{stat?.pitcher_ip}</td>
                                        <td>{stat?.avg_hitter_matchup_rating}</td>
                                        <td style={wobaStyles}>{stat?.avg_hitter_matchup_woba}</td>
                                        <td style={isoStyles}>{stat?.avg_hitter_matchup_iso}</td>
                                        <td style={hardContactStyles}>{stat?.avg_hitter_matchup_hard_contact}%</td>
                                        <td style={strikeoutRateStyles}>{stat?.avg_hitter_matchup_k_pct}%</td>
                                        <td style={groundballRateStyles}>{stat?.avg_hitter_matchup_gb_pct}%</td>
                                        <td>{stat?.num_elite_rated_hitters}</td>
                                        <td>{stat?.num_poor_rated_hitters}</td>
                                        <td>{stat?.num_hitters_above_avg_k_pct}</td>
                                        <td>{stat?.num_hitters_above_avg_gb_pct}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default PitcherRatingsTable