import React, {useContext, useRef} from "react";
import { UserContext } from '../Context/UserContext';
import {useNavigate} from "react-router-dom"

function GameBar() {
    const {state} = useContext(UserContext);
    const navigate = useNavigate()
    const gameBarRef = useRef(null);

    function getAbbreviatedName(fullName) {
        if (fullName === undefined || fullName === "")
            return " "
        else {
            // Split the full name into first name and last name
            const [firstName, lastName] = fullName.split(' ');
            // Get the first initial
                const firstInitial = firstName.charAt(0);
            // Combine the first initial and last name
                return `${firstInitial}. ${lastName}`
        }
    }

    function navigateToSpecificGame(game) {
        navigate(`/game/${game.away_team}/vs/${game.home_team}`)
        window.scrollTo(0, 0)
    }

    function scrollGameBar(direction) {
        if (gameBarRef.current) {
            const scrollDistance = window.innerWidth <= 600 ? 50 : 150
            const scrollBy = direction === "left" ? -scrollDistance : scrollDistance
            const scrollDuration = 150
            const startTime = performance.now()
            
            function animateScroll(timestamp) {
                const elapsed = timestamp - startTime
                const progress = Math.min(elapsed/scrollDuration, 1)
                gameBarRef.current.scrollLeft += scrollBy * progress

                if (progress < 1) {
                    requestAnimationFrame(animateScroll)
                }
            }

            requestAnimationFrame(animateScroll)
        }
    }
    
    if (state.games?.length <= 0)
    return (
        <div></div>
    )
    else
    return (
        <div>
            <h3 id="gameFilter">Game Filter</h3>
            <p id="filterP">Click For Game Specific Information</p>
            <div className="gameBarContainer">
                <button className="scrollButton-left" onClick={() => scrollGameBar("left")}>⬅️</button>
                <div ref={gameBarRef} className="gameBar">
                    {state?.games?.map((game) => {
                        const abbreviatedAwaySP = getAbbreviatedName(game.away_team_SP);
                        const abbreviatedHomeSP = getAbbreviatedName(game.home_team_SP);
                        const awayTeamStyles = {backgroundColor: state.teamColors[game.away_team] || "transparent"};
                        const homeTeamStyles = {backgroundColor: state.teamColors[game.home_team] || "transparent"}
                        return (
                        <ul className='gameCards' key={game.id}>
                            <li onClick={() => navigateToSpecificGame(game)}>
                                <div style={awayTeamStyles}>{`${game.away_team}: ${abbreviatedAwaySP}`}</div>
                                <div style={homeTeamStyles}>{`at ${game.home_team}: ${abbreviatedHomeSP}`}</div>
                            </li>
                        </ul>
                        )
                    })}
                </div>
                <button className="scrollButton-right" onClick={() => scrollGameBar("right")}>➡️</button>
            </div>
        </div>
    )
}

export default GameBar