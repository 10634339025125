import React, {useEffect, useState} from "react";
import Cookies from 'js-cookie'
import SignupLoginButtons from "./SignupLoginButtons";

function FreePopUp() {
    const [isVisible, setIsVisible] = useState(false)
    //console.log('freePopUpVisible', isVisible)

    useEffect(() => {
        const freeWorldSeriesAcknowledged = Cookies.get('freeWorldSeriesAcknowledged')
        if (!freeWorldSeriesAcknowledged) {
            setIsVisible(true)
        }
    }, [])

    function handleAcknowledge() {
        Cookies.set('freeWorldSeriesAcknowledged', 'true', {expires: 7, sameSite: 'Lax'})
        setIsVisible(false)
    }

    if (!isVisible) {
        return null
    }

    return (
        <div className="freePopUp">
            <div className="freePopUpContent">
                <h2>FREE Premium for the World Series</h2>
                <h3>Just sign up or login to gain access!</h3>
                <SignupLoginButtons />
                <h2>Premium Features</h2>
                <p id="everythingInFree">Free Default Ratings Plus:</p>
                <ul>
                    <li>Current Season Hitter Ratings</li>
                    <li>Percent Change in Matchup Stats on Hitter Ratings Table</li>
                    <li>Player Stats & Trends</li>
                    <li className="example">Example: Cody Bellinger Has Recorded a Hit 70% of the Time When He Has an Elite Rating</li>
                    <li>Pitcher Ratings*</li>
                    <li className="example">Current Season & 3 Year Time Frame</li>
                    <li className="example">*Brand New Ratings Being Tested</li>
                    {/* <li>Favorite Hitter and Pitchers</li> */}
                </ul>
                <p>Questions? Email: contactbattersbox@gmail.com</p>
                <button id="closeFreeButton" onClick={handleAcknowledge}>❌</button>
            </div>
        </div>
    )
}

export default FreePopUp