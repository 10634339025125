export const initialState = {
    initialLoad: true,
    paymentStatus: false,
    user: {},
    loggedIn: false,
    games: [],
    authors: [],
    articles: [],
    dailyCurrentSeasonStats: [],
    dailyThreeYearStats: [],
    dailyPitcherCurrentSeasonStats: [],
    dailyPitcherThreeYearStats: [],
    teamTrends: [],
    teamColors: {
        'ARI': 'rgba(167, 25, 48, 0.7)',
        'ATL': 'rgba(206, 17, 65, 0.7)',
        'BAL': 'rgba(223, 70, 1, 0.7)',
        'BOS': 'rgba(189, 48, 57, 0.7)',
        'CHC': 'rgba(14, 51, 134, 0.7)',
        'CHW': 'rgba(39, 37, 31, 0.7)',
        'CIN': 'rgba(198, 1, 31, 0.7)',
        'CLE': 'rgba(0, 56, 93, 0.7)',
        'COL': 'rgba(51, 51, 102, 0.7)',
        'DET': 'rgba(12, 35, 64, 0.7)',
        'HOU': 'rgba(0, 45, 98, 0.7)',
        'KCR': 'rgba(0, 70, 135, 0.7)',
        'LAA': 'rgba(186, 0, 33, 0.7)',
        'LAD': 'rgba(0, 90, 156, 0.7)',
        'MIA': 'rgba(0, 163, 224, 0.7)',
        'MIL': 'rgba(18, 40, 75, 0.7)',
        'MIN': 'rgba(0, 43, 92, 0.7)',
        'NYM': 'rgba(0, 45, 114, 0.7)',
        'NYY': 'rgba(0, 48, 135, 0.7)',
        'OAK': 'rgba(0, 56, 49, 0.7)',
        'PHI': 'rgba(232, 24, 40, 0.7)',
        'PIT': 'rgba(39, 37, 31, 0.7)',
        'STL': 'rgba(196, 30, 58, 0.7)',
        'SDP': 'rgba(47, 36, 29, 0.7)',
        'SFG': 'rgba(253, 90, 30, 0.7)',
        'SEA': 'rgba(12, 44, 86, 0.7)',
        'TBR': 'rgba(9, 44, 92, 0.7)',
        'TEX': 'rgba(0, 50, 120, 0.7)',
        'TOR': 'rgba(19, 74, 142, 0.7)',
        'WSN': 'rgba(171, 0, 3, 0.7)'
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case 'setPaymentStatus':
            return {
                ...state,
                paymentStatus: action.payload
            }
        case 'setUser':
            return {
                ...state,
                user: action.payload
            }
        case 'setLoad':
            return {
                ...state,
                initialLoad: false    
            }
        case 'login':
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case 'signup':
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case 'logout':
            return {
                ...state,
                user: {},
                loggedIn: false
            }
        case 'setLoggedIn':
            return {
                ...state,
                loggedIn: action.payload
            }
        case 'fetchGames':
            return {
                ...state,
                games: action.payload
            }
        case 'fetchTeamTrends':
            return {
                ...state,
                teamTrends: action.payload
            }
        case 'fetchAuthors':
            return {
                ...state,
                authors: action.payload
            }
        case 'fetchArticles':
            return {
                ...state,
                articles: action.payload
            }
        case 'fetchDailyCurrentSeasonStats':
            return {
                ...state,
                dailyCurrentSeasonStats: action.payload
            }
        case 'fetchDailyThreeYearStats':
            return {
                ...state,
                dailyThreeYearStats: action.payload
            }
        case 'fetchDailyPitcherCurrentSeasonStats':
            return {
                ...state,
                dailyPitcherCurrentSeasonStats: action.payload
            }
        case 'fetchDailyPitcherThreeYearStats':
            return {
                ...state,
                dailyPitcherThreeYearStats: action.payload
            }
        default:
            return state;
    }
}