import React, {useState, useContext, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import GameBar from "./GameBar";
import NotPremium from "./NotPremium";
import Logos from "./Logos";
import PitcherRatingsTable from "./PitcherRatingsTable";

function PitcherRatings() {
    const {state} = useContext(UserContext);
    const [ratingTimeframe, setRatingTimeframe] = useState(false) //false is default (last 3 seasons), true is current season only
    const [formattedDate, setFormattedDate] = useState("")
    //console.log('ratingTimeFrame', ratingTimeframe)
    //console.log('state', state)

    useEffect(() => {
        if (state?.games.length > 0) {
            // const statCreatedDate = state?.games[0].created_at
            // const [datePart] = statCreatedDate.split('T')
            // const [year, month, day] = datePart.split('-')
            //setFormattedDate(`${parseInt(month)}-${day}`)
            setFormattedDate(`9-29`)
        }
    }, [state.games])

    function handleChange() {
        setRatingTimeframe(prevTimeframe => !prevTimeframe)
    }

    const isUser = state.loggedIn ? true : false
    // const subscription = state.user.subscription
    // //console.log('sub', subscription)
    // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    // //console.log('hasSub?', hasSub)
    // const today = new Date()
    // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    return (
        <div>
            <Logos />
            {!isUser && (
                <div className="userNotPremium">
                    <NotPremium />
                </div>
            )}
            {isUser && (
                <div>
                    <div className="ratings-header">
                        {formattedDate && (<h2 className="ratings-header">MLB Pitcher Ratings for {formattedDate}</h2>)}
                    </div>
                    <GameBar />
                    <div className="ratings-dropdown">
                        <label>Select Rating Timeframe: </label>
                        <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                            <option>Default (Last 3 Seasons)</option>
                            <option>Current Season Only</option>
                        </select>
                    </div>
                    <PitcherRatingsTable ratingTimeframe={ratingTimeframe}/>
                </div>
            )}
        </div>
    )
}

export default PitcherRatings