import React from "react";
// import {useNavigate} from "react-router-dom"
import Logos from "./Logos";
import SignupLoginButtons from "./SignupLoginButtons";

function NotPremium() {
    // const navigate = useNavigate()
    
    // function handlePremiumNavigation() {
    //     navigate('/checkout')
    //     window.scrollTo(0, 0)
    // }

    // function navigateToLogin() {
    //     navigate('/login')
    //     window.scrollTo(0, 0)
    // }

    return (
        <div>
            <Logos />
            <div className="notPremium">
                <h2>Only Premium Subscribers May Access This Page</h2>
                <h2>Premium is FREE for the World Series. Just sign up or login to gain access!</h2>
                <SignupLoginButtons />
                {/* <button onClick={handlePremiumNavigation}>Get Premium</button>
                <h3>Have Premium?</h3>
                <button onClick={navigateToLogin}>Login</button> */}
            </div>
        </div>
    )
}

export default NotPremium